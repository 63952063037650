import React from 'react';
import { Container } from 'react-grid-system';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ClaimDTO, ClaimDTOStatusEnum, PolicyDTO } from '@reposit/api-client';
import { ExpandableContent } from '../../../components/ExpandableContent';
import PolicyHeader from '../../../components/Reposit/PolicyHeader';
import moment from 'moment';
import RepositCard from '../../../components/Reposit/RepositCard';
import { Header4 } from '../../../components/Typography';
import InlineTable from '../../../components/InlineTable';
import Button from '../../../components/Button';
import { ACKNOWLEDGE_CASE_SENT_TO_INSURER_STORE_KEY, setCurrentRepositActionModal } from '../../../redux/reposit/reposit.actions';
import FlashMessage from '../../../components/FlashMessage';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';

const TableSpacer = styled.div`
  height: 20px;
`;

const ActionButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;
const PoliciesContainer = styled.div`
  padding: 10px 35px;
  margin-bottom: 5px;
`;
interface RepositPoliciesProps {
    order?: {
        policies?: PolicyDTO[];
    };
    policiesWithItems: any[];
    claim?: ClaimDTO;
}

const PoliciesCard: React.FC<RepositPoliciesProps> = ({ order, policiesWithItems, claim }) => {
    const dispatch = useDispatch();
    const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([ACKNOWLEDGE_CASE_SENT_TO_INSURER_STORE_KEY]);

    if (!order || !order.policies || !order.policies.length) {
        return null;
    }

    const {
        insuranceSentAt = null,
        status = null,
        insuranceOutcomeExpectedAt = null
    } = claim || {};

    const notAcknowledgedYet = !insuranceSentAt;
    const hasClaim = claim && claim.id;
    const isInViableState = status && [ClaimDTOStatusEnum.AWAITINGRESPONDENTS, ClaimDTOStatusEnum.ARBITRATED].includes(status);

    const canAcknowledge = notAcknowledgedYet
        && hasClaim
        && isInViableState;

    return (
        <RepositCard
            flush
            title="Policies"
            flashMessage={
                flashMessagePayload ? (
                    <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
                ) : undefined
            }
            headerContent={
                canAcknowledge ? (
                    <ActionButtonWrapper>
                        <Button
                            buttonType="warning"
                            onClick={() => dispatch(setCurrentRepositActionModal({ name: 'repositSendPolicyModal' }))}
                        >
                            Case sent to insurer
                        </Button>
                    </ActionButtonWrapper>
                ) : null
            }
        >
            <Container>
                {insuranceSentAt && insuranceOutcomeExpectedAt && (
                    <>
                        <Header4 style={{ padding: '0 20px' }}>Details</Header4>
                        <InlineTable
                            items={[
                                {
                                    key: 'Sent to Insurer',
                                    value: moment(insuranceSentAt).format('DD/MM/YYYY'),
                                },
                                {
                                    key: 'Expected result date',
                                    value: moment(insuranceOutcomeExpectedAt).format('DD/MM/YYYY'),
                                }
                            ]}
                        />
                        <TableSpacer />
                    </>
                )}
                <Header4 style={{ padding: '0 20px' }}>Policy Information</Header4>
                {policiesWithItems.map((p) => {
                    return (
                        <PoliciesContainer key={p.id}>
                            <ExpandableContent header={<PolicyHeader policy={p} />} hiddenContent={<InlineTable items={p.data} />} />
                        </PoliciesContainer>
                    );
                })}
            </Container>
        </RepositCard>
    );
};

export default PoliciesCard; 